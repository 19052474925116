import React, { useState } from 'react';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import axios from 'axios';
import { baseAreWeApiUrl } from './consts';

// Define the type for the image data
type ImageData = {
    id: string;
    url: string;
};

// Fetch images with is_reviewed = false
const fetchImages = async (): Promise<ImageData[]> => {
    const response = await axios.post(`${baseAreWeApiUrl}/console/searches`);
    return response.data;
};

// Create a new conversation
const createConversation = async (): Promise<void> => {
    await axios.post(`${baseAreWeApiUrl}/console/conversations`);
};

// Update search record
const updateSearch = async ({ id, conversation_id, is_reviewed }: { id: string, conversation_id: string, is_reviewed: boolean }): Promise<void> => {
    await axios.put(`${baseAreWeApiUrl}/console/searches/${id}`, { conversation_id, is_reviewed });
};

export const Gallery: React.FC = () => {
    const queryClient = useQueryClient();
    const { data: images, isLoading } = useQuery<ImageData[]>({
        queryKey: ['images'],
        queryFn: fetchImages,
    });
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [stickyImage, setStickyImage] = useState<string | null>(images ? images[0].url : null);
    const [showModal, setShowModal] = useState(false);

    const mutation = useMutation<void, Error, void>({
        mutationFn: createConversation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['images'] });
        },
    });


    const handleImageClick = (image: string) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const handleConfirm = async () => {
        if (selectedImage && stickyImage) {
            await updateSearch({ id: selectedImage, conversation_id: stickyImage, is_reviewed: true });
            setShowModal(false);
            setSelectedImage(null);
        }
    };

    const handleCancel = () => {
        setShowModal(false);
        setSelectedImage(null);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {images && images.map((image) => (
                <img
                    key={image.id}
                    src={image.url}
                    alt="To be reviewed"
                    onClick={() => handleImageClick(image.url)}
                    style={{ cursor: 'pointer', margin: '10px', width: '100px', height: '100px' }}
                />
            ))}
            {showModal && (
                <MatchConfirmationDialog
                    stickyImage={stickyImage}
                    selectedImage={selectedImage}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
};

const StickyImage: React.FC<{ image: string }> = ({ image }) => (
    <img src={image} alt="Sticky" style={{ width: '100px', height: '100px' }} />
);

const MatchConfirmationDialog: React.FC<{
    stickyImage: string | null,
    selectedImage: string | null,
    onConfirm: () => void,
    onCancel: () => void
}> = ({ stickyImage, selectedImage, onConfirm, onCancel }) => (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', border: '1px solid black' }}>
        <h2>Confirm Match</h2>
        <div>
            <StickyImage image={stickyImage || ''} />
            <StickyImage image={selectedImage || ''} />
        </div>
        <button onClick={onConfirm}>Confirm</button>
        <button onClick={onCancel}>Cancel</button>
    </div>
);

export default Gallery;