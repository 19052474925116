// src/GoogleOAuthPage.tsx
import React from 'react';
import { createClient } from '@supabase/supabase-js';
import GoogleButton from 'react-google-button'
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";

const supabaseUrl = 'https://wsldrtcpzswsaghvkgft.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndzbGRydGNwenN3c2FnaHZrZ2Z0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTEwNDk2MjIsImV4cCI6MjAyNjYyNTYyMn0.u7VzhkacOnnpoKQTPWuOLBrn9qhuY86lBIztUDvUlkw';
const supabase = createClient(supabaseUrl, supabaseKey);

const handleFacebookLogin = async () => {
    const { error, data } = await supabase.auth.signInWithOAuth({
        provider: 'facebook',
    });
    console.log(data, "sb data and goog data?");
    if (error) console.error('Error logging in with Google:', error.message);
};

const GoogleOAuthPage: React.FC = () => {
    const handleGoogleLogin = async () => {
        const { error, data } = await supabase.auth.signInWithOAuth({
            provider: 'google',
        });
        console.log(data, "sb data and goog data?");
        if (error) console.error('Error logging in with Google:', error.message);
    };

    const handleFacebookLogin = async () => {
        const { error, data } = await supabase.auth.signInWithOAuth({
            provider: 'facebook',
        });
        console.log(data, "sb data and goog data?");
        if (error) console.error('Error logging in with Google:', error.message);
    };


    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Login or Create New Account</h1>
            <div style={{display: "flex", width: "100%", height: "50%", alignItems: "center", justifyItems: "center", justifyContent: "center"}}>
            <GoogleLoginButton
                onClick={handleGoogleLogin}
                style={{width: "240px", height: "50px", marginLeft: "5px"}}/>
            <FacebookLoginButton
                                 onClick={handleFacebookLogin}
                                 style={{width: "240px", height: "50px", marginLeft: "5px"}}/>
            </div>
        </div>
    );
};

export default GoogleOAuthPage;