import React from 'react';

const TermsOfServiceContent: React.FC = () => {
    return (
        <div>
            <h1>Terms of Service</h1>
            <p>Last updated: Sept 3, 2024</p>
            <p>Please read these terms of service ("terms", "terms of service") carefully before using AreWe mobile application (the "service") operated by AreWe Inc. ("us", 'we", "our").</p>

            <h2>Conditions of Use</h2>
            <p>We will provide their services to you, which are subject to the conditions stated below in this document. Every time you visit this app, use its services or make a purchase, you accept the following conditions. This is why we urge you to read them carefully.</p>

            <h2>Privacy Policy</h2>
            <p>Before you continue using our app we advise you to read our privacy policy [link to privacy policy] regarding our user data collection. It will help you better understand our practices.</p>

            <h2>Copyright</h2>
            <p>Content published on this app (digital downloads, images, texts, graphics, logos) is the property of AreWe Inc. and/or its content creators and protected by international copyright laws. The entire compilation of the content found on this app is the exclusive property of AreWe Inc. with copyright authorship for this compilation by AreWe Inc.</p>

            <h2>Communications</h2>
            <p>The entire communication with us is electronic. Every time you send us an email or visit our app, you are going to be communicating with us. You hereby consent to receive communications from us. If you subscribe to the news on our app, you are going to receive regular emails from us. We will continue to communicate with you by posting news and notices on our app and by sending you emails. You also agree that all notices, disclosures, agreements, and other communications we provide to you electronically meet the legal requirements that such communications be in writing.</p>

            <h2>Applicable Law</h2>
            <p>By visiting this app, you agree that the laws of [Your Location], without regard to principles of conflict laws, will govern these terms of service, or any dispute of any sort that might come between AreWe Inc. and you, or its business partners and associates.</p>

            <h2>Disputes</h2>
            <p>Any dispute related in any way to your visit to this app or to products you purchase from us shall be arbitrated by state or federal court [Your Location] and you consent to exclusive jurisdiction and venue of such courts.</p>

            <h2>Comments, Reviews, and Emails</h2>
            <p>Visitors may post content as long as it is not obscene, illegal, defamatory, threatening, infringing of intellectual property rights, invasive of privacy or injurious in any other way to third parties. Content has to be free of software viruses, political campaign, and commercial solicitation.</p>
            <p>We reserve all rights (but not the obligation) to remove and/or edit such content. When you post your content, you grant AreWe Inc. non-exclusive, royalty-free and irrevocable right to use, reproduce, publish, modify such content throughout the world in any media.</p>

            <h2>License and Site Access</h2>
            <p>We grant you a limited license to access and make personal use of this app. You are not allowed to download or modify it. This may be done only with written consent from us.</p>

            <h2>User Account</h2>
            <p>If you are an owner of an account on this app, you are solely responsible for maintaining the confidentiality of your private user details (username and password). You are responsible for all activities that occur under your account or password.</p>
            <p>We reserve all rights to terminate accounts, edit or remove content and cancel orders in their sole discretion.</p>
        </div>
    );
};

const TermsOfServicePage: React.FC = () => {
    return (
        <TermsOfServiceContent />
    );
};

export default TermsOfServicePage;