// @ts-nocheck
/* @eslint-disable */
import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import styled, { createGlobalStyle } from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import PrivacyPage from './PrivacyPage';
import TermsOfServicePage from "./TermsOfService";
import GoogleOAuthPage from "./GoogleOAuthPage";
import KairosAdminCallsPage from "./KairosAdminCalls";
import Gallery from "./MatchReviewConsole";
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()

export const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    overflow: hidden; // Prevent scrolling
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(51,51,51); // Example background color
  }
`;



const LogoImg = styled.img`
  max-width: 100%;
  height: auto;
  @media (max-width: 768px) {
    width: 50%; // Resize on mobile devices
  }
`;

const Logo: React.FC = () => {
  return <LogoImg src="/arewelogo.png" alt="Startup Logo" />;
};

const EmailConfirm = () => {
    return (
        <p>Check your inbox to confirm your pre-registration!</p>
    )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmailInput = styled.input`
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 300px;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const sendEmailWithMailgun = async () => {
    const apiKey = 'YOUR_MAILGUN_API_KEY';
    const domain = 'YOUR_MAILGUN_DOMAIN';
    const url = `https://api.mailgun.net/v3/${domain}/messages`;
    const headers = {
        'Authorization': `Basic ${btoa(`api:${apiKey}`)}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    const body = new URLSearchParams({
        from: 'Excited User <mailgun@YOUR_DOMAIN.com>',
        to: 'recipient@example.com',
        subject: 'Hello from Mailgun',
        text: 'This is a test email sent via Mailgun.',
    }).toString();

    try {
        const response = await fetch(url, { method: 'POST', headers, body });
        if (!response.ok) throw new Error('Email send failed');
        console.log('Email sent successfully with Mailgun');
    } catch (error) {
        console.error('Mailgun email send error:', error);
    }
};

const EmailSignup: React.FC = () => {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setSubmitted(true);

        // Mock network request
        await fetch('/api/subscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        }).then(res => res.json().catch(e => {
            // no way to look cool about an error here, just try it again and dont say shit
        }));

        // TODO - handle from response not timeout lol
        // Wait for 3000ms before showing the confirmation message
        setTimeout(() => {
            setEmail(''); // Clear the email input if needed
            alert("You're signed up, check your inbox to confirm registration!"); // Or update the UI as required
            setSubmitted(false); // Reset submission state if you want to allow another action
        }, 3000);
    };


    if (submitted) {
        return <p>{submitted ? "You're signed up, check your inbox to confirm registration!" : "Submitting..."}</p>; // Show a loading state or similar message
    }

    return (
        <Form onSubmit={handleSubmit}>
            <EmailInput
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <SubmitButton type="submit">Sign Up</SubmitButton>
        </Form>
    );
};


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

const Container = styled.div`
  text-align: center;
`;

const StyledText = styled.p`
  color: white;
  font-family: Courier New;
  font-size: 20px;
  
`

const LandingPage: React.FC = () => {
  return (
      <>
        <GlobalStyle />
        <Container>
          <Logo />
{/*<StyledText>Do you want to know?</StyledText>*/}
          {/*<EmailSignup />*/}
        </Container>
      </>
  );
};

const App: React.FC = () => {
    return (
        <QueryClientProvider client={queryClient}>
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/service" element={<TermsOfServicePage />} />
            <Route path="/login" element={<GoogleOAuthPage />} />
            <Route path="/kairos-admin" element={<KairosAdminCallsPage/>} />
            <Route path="/review" element={<Gallery/>} />
        </Routes>
            </QueryClientProvider>
    );
};

export default App;