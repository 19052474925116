import React, {useEffect, useState} from 'react';
import {createClient} from '@supabase/supabase-js';
import  {JwtPayload, jwtDecode} from 'jwt-decode';
import styled from 'styled-components';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { baseAreWeApiUrl } from './consts';
import axios from 'axios';

// Define the type for the image data
type ImageData = {
    id: string;
    url: string;
};

// Fetch images with is_reviewed = false
const fetchImages = async (): Promise<ImageData[]> => {
    const response = await axios.post(`${baseAreWeApiUrl}/console/searches`);
    return response.data;
};

// Create a new conversation
const createConversation = async (): Promise<void> => {
    await axios.post(`${baseAreWeApiUrl}/console/conversations`);
};

// Update search record
const updateImageMetadata = async ({ filename, is_reviewed }:
 { filename: string, is_reviewed: boolean }): Promise<void> => {
    await axios.put(`${baseAreWeApiUrl}/console/images_metadata`,
        { filename,
            is_reviewed });
};


const StickyImage: React.FC<{ image: SupabaseStorageResponseObject | null }> = ({ image }) => {
    if (!image) return null;
    return <img src={image.supabaseMediaUrl} alt="Sticky" style={{ width: '100px', height: '100px' }} />;
};

const ButtonContainer = styled.div`
  width: 100vw;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
    margin-top: 40px;
  gap: 20px; /* Adjust the gap as needed */
`;

/*
*  NOTE:
*  see: https://stackoverflow.com/questions/74330969/how-do-i-fetch-multiple-images-from-supabase-storage
*   for how to get a list instead of query per url/image, also likely a way to make it work with getting all the metadata
*
* */

const supabaseUrl = 'https://wsldrtcpzswsaghvkgft.supabase.co';

// it's the public key, settle the fuck down GH warning bot
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndzbGRydGNwenN3c2FnaHZrZ2Z0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTEwNDk2MjIsImV4cCI6MjAyNjYyNTYyMn0.u7VzhkacOnnpoKQTPWuOLBrn9qhuY86lBIztUDvUlkw';
const supabase = createClient(supabaseUrl, supabaseKey);

// TODO: update this in the proxy or somwhere, it's not like a secret but should be configurable by stage you animal
const defaultGalleryName = 'arewe_search_image_gallery_dev_1';

async function uploadToSupabase(bucket: string, file: File) {
    const { data, error } = await supabase.storage.from(bucket).upload(file.name, file);
    if (error) throw error;
    return data;
}

async function deleteFromSupabase(bucket: string, fileName: string) {
    const { data, error } = await supabase.storage.from(bucket).remove([fileName]);
    if (error) throw error;
    return data;
}

async function listSupabaseFiles(bucket: string) {
    const { data, error } = await supabase.storage.from(bucket).list();
    if (error) throw error;
    return data;
}

const createConversationFromAdminMatch = async (storageObjects: SupabaseStorageResponseObject[], avatar?: string) => {
    try {
        const response = await axios.post(`${baseAreWeApiUrl}/console/create-conversation-from-admin-match`, {
            storageObjects,
            avatar
        });
        return response.data;
    } catch (error) {
        console.error('Error creating conversation:', error);
        throw error;
    }
};

export async function getSupabaseRecord(subjectId: string) {
    const { data, error } = await supabase
        .from('images_metadata')
        .select('*')
        .eq('filename', subjectId)
        .single();

    if (error) {
        throw error;
    }

    return data;
}

export async function getSupabaseRecords(subjectIds: Array<string>) {
    const { data, error } = await supabase
        .from('images_metadata')
        .select('*')
        .in('filename', subjectIds)

    if (error) {
        throw error;
    }

    return data;
}

type SupabaseStorageResponseObject = {
    supabaseMediaUrl: string;
    uploaderUserId: string;
    filename: string;
    is_reviewed: boolean;
    created_at: string;
    id: string;
    file_extension: string;
};

async function getSupabaseStorageResponseObjects(subjectIds: string[]): Promise<SupabaseStorageResponseObject[]> {
    const results = await getSupabaseRecords(subjectIds).then(arr => {
        return arr.map((imageMetadataRecord: Record<string, any>) => {
            const supabaseMediaUrl = generateSupabaseMediaUrl({
                supabaseProjectUrl: supabaseUrl,
                bucket: 'search_images',
                uploaderUserId: imageMetadataRecord.user_id,
                filename: imageMetadataRecord.filename,
                fileExtension: imageMetadataRecord.file_extension,
            });

            return {
                supabaseMediaUrl,
                uploaderUserId: imageMetadataRecord.user_id,
                filename: imageMetadataRecord.filename,
                is_reviewed: imageMetadataRecord.is_reviewed,
                created_at: imageMetadataRecord.created_at,
                id: imageMetadataRecord.id,
                file_extension: imageMetadataRecord.file_extension

            };
        });
    });

    // Sort results by created_at
    results.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

    return results;
}

function generateSupabaseMediaUrl({
                                      supabaseProjectUrl,
                                      bucket,
                                      uploaderUserId,
                                      filename,
                                      fileExtension
                                  }: {
    protocol?: string;
    supabaseProjectUrl?: string;
    bucket?: string;
    uploaderUserId: string;
    filename: string;
    fileExtension: string;
}): string {

    const checkedSupabaseProjectUrl = supabaseProjectUrl || 'https://wsldrtcpzswsaghvkgft.supabase.co';
    const checkedBucket = bucket || 'search_images';

    return `${checkedSupabaseProjectUrl}/storage/v1/object/public/${checkedBucket}/${uploaderUserId}/${filename}.${fileExtension}`;
}

// const baseUrl = 'https://api.arewe.date';
 const baseUrl = 'http://localhost:3030';

const headers = {
    'Content-Type': 'application/json',
};

async function enroll(imageUrl: string, subjectId: string, galleryName: string, file: File) {
    const response = await fetch(`${baseUrl}/kairos-api-proxy/enroll`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            image: imageUrl,
            subject_id: subjectId,
            gallery_name: galleryName,
        }),
    });
    const result = await response.json();
    if (response.ok) {
        await uploadToSupabase(galleryName, file);
    }
    return result;
}

async function galleryRemoveSubject(galleryName: string, subjectId: string, fileName: string) {
    const response = await fetch(`${baseUrl}/kairos-api-proxy/gallery/remove_subject`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            gallery_name: galleryName,
            subject_id: subjectId,
        }),
    });
    const result = await response.json();
    if (response.ok) {
        await deleteFromSupabase(galleryName, fileName);
    }
    return result;
}

async function validateSame(galleryName: string) {
    const supabaseFiles = await listSupabaseFiles(galleryName);
    const supabaseFileNames = supabaseFiles.map(file => file.name);
    const response = await fetch(`${baseUrl}/kairos-api-proxy/gallery/view`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            gallery_name: galleryName,
        }),
    });
    const result = await response.json();

    const kairosFileNames = result.subjects.map((subject: Record<string, any>) => subject.subject_id);

    const allMatch = supabaseFileNames.every(fileName => kairosFileNames.includes(fileName));
    return allMatch;
}

async function createGalleryAndBucket(galleryName: string) {
    // Create gallery in Kairos
    const response = await fetch(`${baseUrl}/kairos-api-proxy/gallery/create`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            gallery_name: galleryName,
        }),
    });
    const result = await response.json();
    if (response.ok) {
        // Create bucket in Supabase
        const { data, error } = await supabase.storage.createBucket(galleryName);
        if (error) throw error;
        return { kairos: result, supabase: data };
    }
    throw new Error(result.Errors[0].Message);
}

async function deleteGalleryAndBucket(galleryName: string) {
    // Delete gallery in Kairos
    const response = await fetch(`${baseUrl}/kairos-api-proxy/gallery/remove`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            gallery_name: galleryName,
        }),
    });
    const result = await response.json();
    if (response.ok) {
        // Delete bucket in Supabase
        const { data, error } = await supabase.storage.deleteBucket(galleryName);
        if (error) throw error;
        return { kairos: result, supabase: data };
    }
    throw new Error(result.Errors[0].Message);
}

interface SupabaseJWT extends JwtPayload {
    sub: string;
}

function getUserIdFromJWT(token: string): string {
    const decoded: SupabaseJWT = jwtDecode<SupabaseJWT>(token);
    return decoded.sub;
}

type KairosGalleryViewResponse = {
    status: string;
    subject_ids: string[];
};

async function getGalleryImages(galleryName: string = defaultGalleryName): Promise<KairosGalleryViewResponse> {
    const { data: { session }, error } = await supabase.auth.getSession();

    if (error || !session) {
        throw new Error('Error retrieving session or no active session found');
    }

    const supabaseAccessToken = session?.access_token;
    console.log(supabaseAccessToken, "from access local");


    const response = await fetch(`${baseUrl}/kairos-api-proxy/gallery/view`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${supabaseAccessToken}`,
            'Referrer-Policy': 'unsafe-url',
            // 'Referer': 'http://localhost:3000'
        },
        body: JSON.stringify({
            gallery_name: galleryName,
        }),
    });
    if (!response.ok) {
        throw new Error(`Error fetching gallery images: ${response.status} ${response.statusText}`);
    }

    const result = await response.json();
    return result;
}

async function callProtectedRouteExample() {
    const { data: { session }, error } = await supabase.auth.getSession();
    console.log(session, "session data from callProtectedRouteExample")
    if (error) {
        console.error('Error retrieving session:', error);
        return;
    }

    if (!session) {
        console.error('No active session found');
        return;
    }

    const token = session.access_token;

    // Make a request to the server with the auth token
    try {
        const response = await axios.post(
            'http://localhost:3030/kairos-api-proxy/',
            { /* request body */ },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        console.log('Server response:', response.data);
    } catch (err) {
        console.error('Error making request:', err);
    }
}


// async function signInWithGoogleAndMakeRequest() {
//     const { data, error } = await supabase.auth.signInWithOAuth({
//         provider: 'google',
//     });
//
//     if (error) {
//         console.error('Error signing in with Google:', error);
//         return;
//     }
//
//     const { session } = data;
//
//     if (!session) {
//         console.error('No session found');
//         return;
//     }
//
//     const token = session.access_token;
//
//     // Make a request to the server with the auth token
//     try {
//         const response = await axios.post(
//             'http://localhost:3000/api/protected-route',
//             { /* request body */ },
//             {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             }
//         );
//
//         console.log('Server response:', response.data);
//     } catch (err) {
//         console.error('Error making request:', err);
//     }
// }


const EnrollButton: React.FC = () => {
    const handleClick = async () => {
        // Replace with actual parameters
        const imageUrl = 'IMAGE_URL';
        const subjectId = 'SUBJECT_ID';
        const galleryName = 'GALLERY_NAME';
        const file = new File([''], 'filename');
        await enroll(imageUrl, subjectId, galleryName, file);
    };

    return <button onClick={handleClick}>Enroll</button>;
};

const GalleryRemoveSubjectButton: React.FC = () => {
    const handleClick = async () => {
        // Replace with actual parameters
        const galleryName = 'GALLERY_NAME';
        const subjectId = 'SUBJECT_ID';
        const fileName = 'FILENAME';
        await galleryRemoveSubject(galleryName, subjectId, fileName);
    };

    return <button onClick={handleClick}>Gallery Remove Subject</button>;
};

const ValidateSameButton: React.FC = () => {
    const handleClick = async () => {
        // Replace with actual parameters
        const galleryName = 'GALLERY_NAME';
        const result = await validateSame(galleryName);
        console.log(result);
    };

    return <button onClick={handleClick}>Validate Same</button>;
};

const CreateGalleryAndBucketButton: React.FC = () => {
    const handleClick = async () => {
        // Replace with actual parameters
        const galleryName = 'GALLERY_NAME';
        await createGalleryAndBucket(galleryName);
    };

    return <button onClick={handleClick}>Create Gallery And Bucket</button>;
};

const DeleteGalleryAndBucketButton: React.FC = () => {
    const handleClick = async () => {
        // Replace with actual parameters
        const galleryName = 'GALLERY_NAME';
        await deleteGalleryAndBucket(galleryName);
    };

    return <button onClick={handleClick}>Delete Gallery And Bucket</button>;
};


interface KairosGalleryImagesProps {
    items: SupabaseStorageResponseObject[];
}

const KairosGalleryImages: React.FC<KairosGalleryImagesProps> = ({ items }) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {items.map((item, index) => (
                <div key={index} style={{ width: '150px', height: '150px' }}>
                    <img
                        src={item.supabaseMediaUrl}
                        alt={item.uploaderUserId}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    {/*<p>Uploader:{item.uploaderUserId}</p>*/}
                    {/*<p>Filename:{item.filename}</p>*/}
                </div>
            ))}
        </div>
    );
};
const getFullResults = async () => {
    const {subject_ids} = await getGalleryImages();
    const fullResults = await getSupabaseStorageResponseObjects(subject_ids);
    return fullResults;
}



export type SupabaseSession = {
  access_token: string; // The JWT token used for authenticating requests to Supabase services.
  expires_at: number; // The Unix timestamp (in seconds) when the access token will expire.
  expires_in: number; // The number of seconds until the access token expires.
  provider_token: string; // The token provided by the OAuth provider (e.g., Google) used for authentication.
  refresh_token: string; // The token used to obtain a new access token when the current one expires.
  token_type: string; // The type of token, typically "bearer".
  user: {
    id: string; // The unique identifier for the user.
    aud: string; // The audience for the token, typically "authenticated".
    role: string; // The role assigned to the user, typically "authenticated".
    email: string; // The email address of the user.
    email_confirmed_at: string; // The timestamp when the user's email was confirmed.
    full_name: string; // The full name of the user.
    avatar_url: string; // The URL to the user's avatar image.
    provider_id: string; // The ID provided by the OAuth provider.
    sub: string; // The subject identifier for the user, typically the same as the provider_id.
  };
};

const InvisibleComponent = styled.span`
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
`;

export const Gallery: React.FC = () => {
    const queryClient = useQueryClient();
    const { data: images, isLoading } = useQuery<SupabaseStorageResponseObject[]>({
        queryKey: ['images'],
        queryFn: getFullResults
    });

    const [isReviewedFalseItems, setIsReviewedFalseItems] = useState<SupabaseStorageResponseObject[]>([]);
    const [selectedImage, setSelectedImage] = useState<SupabaseStorageResponseObject | null>(null);
    const [stickyImage, setStickyImage] = useState<SupabaseStorageResponseObject | null>(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (images) {
            const filteredImages = images.filter(image => !image.is_reviewed);
            setIsReviewedFalseItems(filteredImages);
            if (filteredImages.length > 0) {
                setStickyImage(filteredImages[0]);
            } else {
                setStickyImage(null); // Ensure stickyImage is null if no unreviewed images are available
            }
        }
    }, [images]);


    const mutation = useMutation<void, Error, void>({
        mutationFn: createConversation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['images'] });
        },
    });

    const handleImageClick = (image: SupabaseStorageResponseObject) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const handleConfirm = async () => {
        if (selectedImage && stickyImage) {
            await updateImageMetadata({
                filename: stickyImage.filename,
                is_reviewed: true });
            await createConversationFromAdminMatch([selectedImage, stickyImage],
                selectedImage.supabaseMediaUrl
                );

            setShowModal(false);
            setSelectedImage(null);
            setIsReviewedFalseItems(prevItems => {
                const updatedItems = prevItems.slice(1);
                setStickyImage(updatedItems.length > 0 ? updatedItems[0]: null);
                return updatedItems;
            });
        }
    };

    const handleCancel = () => {
        setShowModal(false);
        setSelectedImage(null);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const handleNoMatch = async () => {
        if (stickyImage) {
            await updateImageMetadata({
                filename: stickyImage.filename,
                is_reviewed: true
            });
            setIsReviewedFalseItems(prevItems => {
                const updatedItems = prevItems.slice(1);
                setStickyImage(updatedItems.length > 0 ? updatedItems[0] : null);
                return updatedItems;
            });
        }
    };

    return (
        <div>
            {stickyImage && (
                <>
                    <button onClick={handleNoMatch}>no match found</button>
                    <div style={{position: 'sticky', top: '0', width: '250px', height: '250px'}}>
                        <img src={stickyImage.supabaseMediaUrl} alt="Sticky" style={{width: '100%', height: '100%'}}/>
                    </div>
                </>
            )}
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {images?.map((item: SupabaseStorageResponseObject, index: number) =>

                    item.filename === stickyImage?.filename ? <InvisibleComponent key={item.filename}/> : (
                    <div key={item.filename} style={{ width: '150px', height: '150px' }}>
                        <img
                            src={item.supabaseMediaUrl}
                            alt="images in bucket"
                            onClick={() => handleImageClick(item)}
                            style={{ cursor: 'pointer', margin: '10px', width: '100px', height: '100px' }}
                        />
                        {/*<p>Uploader: {item.uploaderUserId}</p>*/}
                        {/*<p>Filename: {item.filename}</p>*/}
                    </div>
                ))}
            </div>
            {showModal && (
                <MatchConfirmationDialog
                    stickyImage={stickyImage}
                    selectedImage={selectedImage}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
};

const MatchConfirmationDialog: React.FC<{
    stickyImage: SupabaseStorageResponseObject | null,
    selectedImage: SupabaseStorageResponseObject | null,
    onConfirm: () => void,
    onCancel: () => void
}> = ({ stickyImage, selectedImage, onConfirm, onCancel }) => (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', border: '1px solid black' }}>
        <h2>Confirm Match</h2>
        <div>
            <StickyImage image={stickyImage} />
            <StickyImage image={selectedImage } />
        </div>
        <button onClick={onConfirm}>Confirm</button>
        <button onClick={onCancel}>Cancel</button>
    </div>
);

const KairosAdminCallsPage: React.FC = () => {
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const checkSession = async () => {
            const {data} = await supabase.auth.getSession();
            console.log(data, "check session");
            if (data.session) {
                console.log(data.session, "===data.session===")
                setIsLoggedIn(true);
            }
        };
        checkSession();
    }, []);

    const handleRegister = async () => {
        const { data, error } = await supabase.auth.signUp({
            email: registerEmail,
            password: registerPassword,
        });
        if (error) {
            console.error('Registration error:', error.message);
        } else {
            setIsLoggedIn(true);
        }
    };

    const handleLogin = async () => {
        const { data, error } = await supabase.auth.signInWithPassword({
            email: loginEmail,
            password: loginPassword,
        });
        console.log(data, "login data")
        if (error) {
            console.error('Login error:', error.message);
        } else {
            setIsLoggedIn(true);
        }
    };

    return (
        <div>
            <h1>Kairos Supabase Functions</h1>
            <ButtonContainer>
                <div>
                    <h2>Register</h2>
                    <input
                        type="email"
                        placeholder="Email"
                        value={registerEmail}
                        onChange={(e) => setRegisterEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={registerPassword}
                        onChange={(e) => setRegisterPassword(e.target.value)}
                    />
                    <button onClick={handleRegister}>Register</button>
                </div>
                <div>
                    <h2>Login</h2>
                    <input
                        style={{height: "100px", width: "100px"}}
                        type="email"
                        placeholder="Email"
                        value={loginEmail}
                        onChange={(e) => setLoginEmail(e.target.value)}
                    />
                    <input
                        style={{height: "100px", width: "100px"}}
                        type="password"
                        placeholder="Password"
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                    />
                    <button onClick={handleLogin}>Login</button>
                </div>
                {isLoggedIn && <p>You are logged in</p>}

                <h1>Kairos Supabase Functions</h1>
                <EnrollButton/>
                <GalleryRemoveSubjectButton/>
                <ValidateSameButton/>
                <CreateGalleryAndBucketButton/>
                <DeleteGalleryAndBucketButton/>
                <Gallery/>
                {/*<KairosGalleryComponent/>*/}

            </ButtonContainer>
        </div>
    );
};


export default KairosAdminCallsPage;